import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const AccessingTheOnBoardingWizard = () => {
  return (
    <Layout>
      <Seo
        title="How to Access The Onboarding Wizard"
        description={`Learn how to access and complete the onboarding wizard in Synkli. Follow the steps for new and existing clients to set up or update your profile.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`accessing-the-on-boarding-wizard`} />
    </Layout>
  )
}

export default AccessingTheOnBoardingWizard
